.nav-link {
    font-family: 'Montserrat Alternates Bold';
    
    &.active {
        color: $primary;
    }
}

.navbar-toggler {
    transition: $transition;

    &:focus {
        outline: none;
    }

    &.open {
        transform: rotate3d(0, 0, 1, 90deg);
    }
}