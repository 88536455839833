@font-face {
    font-family: 'Montserrat Alternates Black';
    src: local('Montserrat Alternates Black'), local('MontserratAlternates-Black'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Black.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Bold';
    src: local('Montserrat Alternates Bold'), local('MontserratAlternates-Bold'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Bold.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Bold Italic';
    src: local('Montserrat Alternates Bold Italic'), local('MontserratAlternates-BoldItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-BoldItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Black Italic';
    src: local('Montserrat Alternates Black Italic'), local('MontserratAlternates-BlackItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-BlackItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates ExtraBold';
    src: local('Montserrat Alternates ExtraBold'), local('MontserratAlternates-ExtraBold'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraBold.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates ExtraLight';
    src: local('Montserrat Alternates ExtraLight'), local('MontserratAlternates-ExtraLight'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraLight.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates ExtraBold Italic';
    src: local('Montserrat Alternates ExtraBold Italic'), local('MontserratAlternates-ExtraBoldItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraBoldItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates ExtraLight Italic';
    src: local('Montserrat Alternates ExtraLight Italic'), local('MontserratAlternates-ExtraLightItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraLightItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Light';
    src: local('Montserrat Alternates Light'), local('MontserratAlternates-Light'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Light.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Italic';
    src: local('Montserrat Alternates Italic'), local('MontserratAlternates-Italic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Italic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Light Italic';
    src: local('Montserrat Alternates Light Italic'), local('MontserratAlternates-LightItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-LightItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Medium Italic';
    src: local('Montserrat Alternates Medium Italic'), local('MontserratAlternates-MediumItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-MediumItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Medium';
    src: local('Montserrat Alternates Medium'), local('MontserratAlternates-Medium'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Medium.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates SemiBold';
    src: local('Montserrat Alternates SemiBold'), local('MontserratAlternates-SemiBold'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-SemiBold.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Regular';
    src: local('Montserrat Alternates Regular'), local('MontserratAlternates-Regular'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Regular.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates SemiBold Italic';
    src: local('Montserrat Alternates SemiBold Italic'), local('MontserratAlternates-SemiBoldItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-SemiBoldItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Thin';
    src: local('Montserrat Alternates Thin'), local('MontserratAlternates-Thin'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Thin.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates Thin Italic';
    src: local('Montserrat Alternates Thin Italic'), local('MontserratAlternates-ThinItalic'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ThinItalic.woff2') format('woff2'),
    url('../../fonts/Montserrat_Alternates/MontserratAlternates-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');