// RWW brand colours
$rww-red: #FF2C20;
$rww-orange: #FF6222;
$rww-work-pink: #FF1552;
$rww-purple: #89016F;
$rww-pink: #F7DEDB; // Light pink, eg header background - HIRE
$rww-deep-brown: #251010;
$rww-mid-grey: #928888;
$rww-light-grey: #C9C3C3;
$rww-font: $rww-purple;

// Override BS colours
$primary: $rww-red;
$secondary: $rww-purple;
$success: #92D050;
$danger: $rww-red;

// Override BS variables
$font-family-base: 'Montserrat Alternates Medium';
$headings-font-family: 'Montserrat Alternates ExtraBold';

// Buttons
$btn-font-family: 'Montserrat Alternates SemiBold';
$btn-border-radius: 5px;

// Jumbotron
$jumbotron-color: #fff;

// Transitions
$transition: all 0.3s ease;

// Body
$body-bg: #f8f8f8;

// Nav
$navbar-light-active-color: $primary;