section {
    @extend .py-3;
}

.bg-pink {
    background-color: $rww-pink;
}

.bg-grey {
    background-color: $rww-light-grey;
}

.text-deep-brown {
    color: $rww-deep-brown !important;
}

.bg-trans-gradient {
    background: linear-gradient(90deg, $rww-red, $rww-orange);
}

